
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.speakers-card {
  @extend %ff-alt;
  @include bottom-dashed-line;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: $spacing;
  color: $c-green-dark;
  cursor: pointer;

  &:hover {
    .speakers-card__picture {
      transform: scale(1.05);
    }
  }
}

.speakers-card__picture-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: $c-gray-lighter;

  &::before {
    content: '';
    display: block;
    padding-top: calc(1 / 1 * 100%);
  }
}

.speakers-card__placeholder {
  @extend %text-center;
  @extend %fw-bold;
  @include center-xy;

  color: $c-gray-dark;
  font-size: 2.6rem;
  line-height: 1.2em;
  user-select: none;
}

.speakers-card__picture {
  @include image-fit;

  transition: transform 0.3s ease-out;
}

.speakers-card__name {
  @extend %fw-bold;

  width: 100%;
  margin-top: $spacing;
  font-size: 2rem;
  line-height: 1.5em;
}

.speakers-card__job {
  @extend %fw-bold;

  flex-grow: 1;
  width: 100%;
  margin-top: $spacing / 2;
  color: $c-gray-medium;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.speakers-card__phone,
.speakers-card__email {
  @extend %text-nodecoration;

  font-size: 1.4rem;
  line-height: 1.2em;
}

.speakers-card__email {
  margin-top: $spacing;
}

.speakers-card__phone {
  margin-top: $spacing / 2;
}

.speakers-card__country {
  @extend %text-uppercase;

  width: 100%;
  margin-top: $spacing / 2;
  color: $c-gray-dark;
  font-size: 1.3rem;
  line-height: 1.2em;
}
