
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































@import '~flickity/dist/flickity.css';

.card-slider {
  @include default-margin;
}

.card-slider-inner {
  .flickity-enabled:focus {
    outline: none;
  }

  /* big buttons, no circle */

  .flickity-button {
    @extend %button-nostyle;

    display: none;

    @include mq(l) {
      top: -10.5rem;

      .is-slider & {
        display: block;
      }
    }
  }

  .flickity-prev-next-button {
    width: 5rem;
    height: 5rem;
    background-color: $c-white;
    border: 1px solid $c-green-dark;
    border-radius: 6.5rem;
    transition: opacity 0.1s $ease-out;

    .arrow {
      position: relative;
      z-index: 1;
      fill: $c-green-dark;
    }

    &.previous {
      right: 60px;
      left: initial;
    }

    &.next {
      right: 0;
      background-color: $c-green-dark;

      .arrow {
        position: relative;
        z-index: 1;
        fill: $c-white;
      }
    }

    &:hover {
      &::before {
        opacity: 0;
        transform: scale(1.5);
      }

      &::after {
        transform: scale(1);
      }
    }
  }

  .flickity-button-icon {
    @include center-xy;

    width: 15px;
    height: 15px;
  }

  .flickity-prev-next-button.no-svg {
    color: $c-black;
  }

  /* hide disabled button */
  .flickity-prev-next-button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.card-slider__title {
  width: col(14);
  min-height: 5rem;
  margin: 0 col(1);
  color: $c-green-dark;
}

.card-slider__list {
  position: relative;
  width: col(14);
  margin: $spacing * 2 col(1) 0 !important; // stylelint-disable-line declaration-no-important

  @include mq(m) {
    margin-top: $spacing * 3 !important; // stylelint-disable-line declaration-no-important
  }

  @include mq(l) {
    margin-top: $spacing * 4 !important; // stylelint-disable-line declaration-no-important
  }
}

.card-slider__item {
  width: 27rem;

  .is-dragging & {
    pointer-events: none;
  }

  & + & {
    margin-left: 6rem;
  }

  @include mq(l) {
    width: 21%;
    min-width: 25rem;

    & + & {
      margin-left: 5%;
    }
  }
}

.card-slider__btn {
  margin-left: col(1);
}
